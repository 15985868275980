<template>
  <div>
    <!-- Main Row -->
    <div class="row">
      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Detail Bahan'">
          <template v-slot:body>
            <div class="row align-items-center">

              <!-- Splash Image -->
              <div class="col-md-4">
                <img
                  class="image"
                  height="200"
                  src="/img/default/posyandu.svg"
                  @load="onImageLoad()"
                />
              </div>

              <!-- Table Data -->
              <div
                class="col"
                v-if="imgLoaded"
              >
                <table class="table mb-3">
                  <tr>
                    <td style="width: 25%"><strong>Nama</strong></td>
                    <td>{{ data.name }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Tersedia</strong></td>
                    <td>{{ data.remaining_stock }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Posyandu</strong></td>
                    <td>{{ data.posyandu_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Deskripsi</strong></td>
                    <td>{{ data.description }}</td>
                  </tr>
                </table>

                <button
                  v-if="btn"
                  @click="
                    $router.push({
                      path: '/material-stock/edit/' + data.id,
                    })
                  "
                  class="btn mx-1 btn-success"
                >
                  <i class="far fa-fw fa-edit"></i> Edit
                </button>
                <button
                  v-if="btn"
                  @click="deleteData"
                  class="btn mx-1 btn-danger"
                >
                  <i class="far fa-fw fa-trash-alt"></i> Hapus
                </button>
              </div>

            </div>
            <button
              @click="
                  $router.push({
                    path: '/material-stock',
                  })
                "
              class="btn mx-1 btn-info mt-5"
            >
              Tutup
            </button>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>

import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {

  data() {
    return {
      // Data
      data: {
        posyandu_name: "",
        name: "",
        description: "",
      },
      // Other
      imgLoaded: false,
      // user access
      btn: false,
    };
  },

  components: {
    Card,
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true;
    },

    async deleteData() {
      let result = await module.delete(
        "material-stocks/" + this.$route.params.id
      );
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push("/material-stock");
      }
    },

    async get() {
      this.data = await module.get(
        "api/material-stocks/" + this.$route.params.id
      );
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push("/material-stock");
      }
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "3002") {
          this.btn = true;
        }
      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Persediaan Bahan", route: "/material-stock" },
      { title: "Detail" },
    ]);
    // Get Data
    this.get();
    this.setActiveButton();
  },

};
</script>